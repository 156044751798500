<template>
  <div class="grid">
    <div class="col-12 card-desktop">
      <Card>
        <template #content>
          <Accordion :activeIndex="0">
            <AccordionTab>
              <template #header>
                <span
                  >Informações do Servidor - {{ store.dados.matricula }} -
                  {{ store.dados.servidor.nome }}</span
                >
                <Tag
                  class="tag-right-side"
                  :value="store.validacao.value"
                  :severity="store.validacao.severity"
                  :icon="store.validacao.icon" />
              </template>
              <div class="col-container-n">
                <Card class="col-n card-border">
                  <template #title> Dados Pessoais </template>
                  <template #content>
                    <p>
                      Nome Completo: <b>{{ store.dados.servidor.nome }}</b>
                    </p>
                    <p>
                      CPF: <b>{{ store.dados.servidor.cpf | mascaraCpf }}</b>
                    </p>
                    <p>
                      E-Mail: <b>{{ store.dados.servidor.email }}</b>
                    </p>
                    <p>
                      Telefone: <b>{{ store.dados.servidor.celular }}</b>
                    </p>
                  </template>
                </Card>
                <div class="col-1"></div>
                <Card class="col-n card-border">
                  <template #title> Dados Profissionais </template>

                  <template #content>
                    <p>
                      Matricula: <b>{{ store.dados.matricula }}</b>
                    </p>
                    <p>
                      Entidade: <b>{{ store.dados.entidade.nome }} </b>
                    </p>
                    <p v-if="store.dados.vinculo">
                      Status:
                      <!-- id 2: PENSIONISTA, id 3: APOSENTADO, id 7: EFETIVO-->
                      <span
                        v-if="
                          store.dados.vinculo.id == 2 ||
                          store.dados.vinculo.id == 3 ||
                          store.dados.vinculo.id == 7
                        "
                        style="color: green">
                        {{ store.dados.vinculo.nome }}
                      </span>
                      <span v-else style="color: red">
                        {{ store.dados.vinculo.nome }}
                      </span>
                      <span v-if="!store.dados.vinculo">
                        TIPO DE VÍNCULO NÃO INFORMADO (VER CONTRACHEQUE)
                      </span>
                    </p>
                    <p v-if="store.dados.dataAdmissao">
                      Data de Admissão:
                      <b>{{ store.dados.dataAdmissao | formatarData }}</b>
                    </p>
                    <p v-if="store.dados.dataAfastamento">
                      Data de Afastamento:<b>{{
                        store.dados.dataAfastamento | formatarData
                      }}</b>
                    </p>
                    <p>
                      Município de Lotação:
                      <b>{{ store.dados.municipioLotacao }}</b>
                    </p>
                    <ProgressSpinner
                      v-if="loadingSpinner"
                      class="loading"
                      strokeWidth="5"
                      style="width: 60px; height: 60px" />
                    <div class="p-fluid formgrid grid">
                      <div
                        v-if="store.dados.afastado"
                        class="field col-6 md:col-3">
                        <span class="dados-matricula-label">Afastado:</span
                        ><span>{{
                          store.dados.afastado == true ? 'Sim' : 'Não'
                        }}</span>
                      </div>
                      <div
                        v-if="store.dados.exonerado"
                        class="field col-6 md:col-3">
                        <span class="dados-matricula-label">Exonerado:</span
                        ><span>{{
                          store.dados.exonerado == true ? 'Sim' : 'Não'
                        }}</span>
                      </div>
                      <div
                        v-if="store.dados.servidor.falecido"
                        class="field col-6 md:col-3">
                        <span class="dados-matricula-label">Falecido:</span
                        ><span>{{
                          store.dados.servidor.falecido == true ? 'Sim' : 'Não'
                        }}</span>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </AccordionTab>
          </Accordion>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <div
        v-if="store.validacao.servidorApto"
        class="flex justify-content-center flex-wrap card-container">
        <div class="flex align-items-center justify-content-center">
          <Button
            v-if="
              ($auth.hasRoleConsignatariaAdmin() || $auth.hasRoleAverbar()) &&
              !store.dados.bloqueada &&
              !suspensaoAtiva
            "
            icon="pi pi-dollar"
            class="mt-2 mr-2 p-button"
            label="Nova Averbação"
            @click="consignacaoOnline()">
          </Button>
          <Button
            v-if="
              ($auth.hasRoleConsignatariaAdmin() ||
                $auth.hasRoleReservaCartao()) &&
              !store.dados.bloqueada &&
              !suspensaoAtiva
            "
            icon="pi pi-credit-card"
            class="mt-2 mr-2 p-button-warning p-button"
            label="Nova Reserva"
            @click="novaReservaCartao()">
          </Button>
          <Button
            v-if="
              ($auth.hasRoleConsignatariaAdmin() ||
                $auth.hasRoleCompraDivida()) &&
              !store.dados.bloqueada &&
              !suspensaoAtiva
            "
            icon="pi pi-shopping-cart"
            class="mt-2 mr-2 p-button-danger p-button"
            label="Nova Portabilidade"
            @click="iniciarCompra()">
          </Button>
          <Button
            v-if="
              ($auth.hasRoleConsignatariaAdmin() ||
                $auth.hasRoleRenegociar()) &&
              !store.dados.bloqueada &&
              !suspensaoAtiva
            "
            icon="pi pi-sync"
            class="mt-2 mr-2 p-button-success p-button"
            label="Renegociação"
            @click="renegociarOnline()">
          </Button>
          <span
            v-if="suspensaoAtiva"
            class="suspensao-error mt-2 p-text-danger">
            <i class="pi pi-exclamation-circle error-icon"></i>
            A consignatária selecionada está suspensa, somente operações de
            Cancelamento podem ser realizadas com esta entidade. Entre em
            contato com a Entidade para regularizar o seu contrato.
          </span>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService.js'
import { servidorStore } from '@/stores/servidor'
import { consignatariaStore } from '@/stores/consignataria'

export default {
  setup() {
    const store = servidorStore()
    const storeConsignataria = consignatariaStore()
    return { store, storeConsignataria }
  },

  data() {
    return {
      dados: {
        servidor: {},
      },
      idMatricula: this.$route.params.id,
      permissoesDoUsuario: [],
      loadingSpinner: false,
      suspensaoAtiva: false,
    }
  },

  created() {
    this.store.$reset()
    this.matriculaService = new MatriculaService(this.$http)
  },

  mounted() {
    this.loading = true
    this.loadingSpinner = true
    const consignataria = JSON.parse(
      localStorage.getItem('consignatariaSelecionada'),
    )
    if (this.store.dados.id !== undefined && this.store.dados.id !== null) {
      this.idMatricula = this.store.dados.id
    }
    this.matriculaService
      .getMatriculaByConsignatariaSelecionada(
        this.idMatricula,
        consignataria.id,
      )
      .then((res) => {
        this.store.$reset()
        this.store.dados = res
        this.store.configuracoes = res.configuracoes
        this.loading = false
        this.loadingSpinner = false
        this.store.checkValidation()
        this.verificaSuspensao(consignataria)
      })
      .catch((err) => {
        this.loading = false
        this.loadingSpinner = false
        this.$toast.add({
          severity: 'error',
          summary: err.response.data.message,
          life: 10000,
        })
        this.$router.back()
      })
  },

  beforeDestroy() {
    this.store.$reset()
  },

  methods: {
    consignacao() {
      this.ocultarTabMenu = false
      this.ocultarBotoes = false
      this.pushRouteTo('consignacao')
    },

    consignacaoOnline() {
      this.ocultarTabMenu = false
      this.ocultarBotoes = false
      this.pushRouteTo('consignacao-online')
    },

    novaReservaCartao() {
      this.ocultarTabMenu = false
      this.ocultarBotoes = false
      this.pushRouteTo('nova-reserva-cartao')
    },

    iniciarCompra() {
      this.ocultarTabMenu = false
      this.ocultarBotoes = false
      this.pushRouteTo('compra-divida')
    },

    renegociar() {
      this.ocultarTabMenu = false
      this.ocultarBotoes = false
      this.pushRouteTo('renegociar')
    },

    renegociarOnline() {
      this.ocultarTabMenu = false
      this.ocultarBotoes = false
      this.pushRouteTo('renegociar-online')
    },

    pushRouteTo(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName }).catch((error) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes(
              'Avoided redundant navigation to current location',
            )
          ) {
            console.log(error)
          }
        })
      }
    },
    verificaSuspensao(consignataria) {
      if (
        consignataria.suspensaoEntidades &&
        consignataria.suspensaoEntidades.some(
          (entidade) => entidade.id === this.store.dados.entidade.id,
        )
      ) {
        this.suspensaoAtiva = true
      } else {
        this.suspensaoAtiva = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul li {
  display: inline-block;
  margin: 0 20px;
}

ul li a {
  margin-bottom: 20px;
}
.dados-matricula-label {
  margin-right: 5px;
  font-weight: bold;
}
.dados-servidor-label {
  margin-right: 5px;
  font-weight: bold;
  font-size: 1.5rem !important;
}
.dados-servidor {
  margin-right: 5px;
  font-size: 1.5rem !important;
}

.servidor-info {
  margin-bottom: 10px;
}

.detalhes-servidor {
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #212529;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-tag {
  font-size: 1rem !important;
}

.facelido {
  background-color: red;
  color: white;
  margin-right: 5px;
  font-weight: bold;
  font-size: 1.5rem;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 1;
  left: 0;
  bottom: 1;
  right: 0;
}

.tag-right-side {
  margin-top: -15px;
  position: absolute;
  right: 0.5em;
  top: 50%;
}

.col-container-n {
  display: table;
  width: 98%;
  margin: auto;
}

.col-n {
  display: table-cell;
}
.suspensao-error {
  color: #721c24;
  background-color: #f8d7da;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #f5c6cb;
  font-size: 1.1rem;
  display: block;
}
</style>
